<template>
  <Page
    has-actions
  >
    <Card
      v-if="$can('read', 'settings.cache')"
      content-class="c-table"
    >
      <b-table
        :data="items"
        :total="items.length"
        :per-page="pagination.perPage"
        :current-page="pagination.page"
        paginated
        hoverable
      >
        <b-table-column
          v-slot="props"
          field="name"
          :label="this.$i18n.tc('name', 1)"
          width="300"
        >
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="description"
          :label="this.$i18n.t('description')"
        >
          {{ props.row.description }}
        </b-table-column>
        <b-table-column
          v-if="$can('delete', 'settings.cache')"
          v-slot="props"
          field="actions"
          width="140"
        >
          <b-button
            type="is-danger"
            size="is-small"
            icon-left="delete"
            class="baseline"
            :loading="loadingClearKey==props.row.key"
            outlined
            @click="clearCache(props.row.key, props.row.name)"
          >
            {{ $t("delete") }}
          </b-button>
        </b-table-column>
      </b-table>
      <Pagination
        :per-page="pagination.perPage"
        :per-pages="rowsPerPage"
        :total="items.length"
        :current-page="pagination.page"
        @update-per-page="val => (pagination.perPage = val)"
        @update-current-page="val => (pagination.page = val)"
      />
    </Card>
  </Page>
</template>

<script>
import Cookie from "js-cookie";
import jwt from "jsonwebtoken";

export default { 
  data() {
    return {
      rowsPerPage: [15, 30],
      pagination: { perPage: 15, page: 1 },
      items: [],
      loading: true,
      loadingClearKey: -1,
      keepDays: null,
    };
  },
  created() {
    this.updateTitle(this.$i18n.t("cache"));
    this.getItems();
  },  
  methods: {
    getItems() {
      if (!this.$can("read", "settings.cache")) return;
      this.loading = true;
      this.$axios
        .get(`/cache`)
        .then(({ data }) => {
          this.items = data.list;
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loading = false));
    },
    clearCache(key, name) {
      if (!this.$can("delete", "settings.cache")) return;
      this.loadingClearKey = key;
      this.$axios
        .delete(`/cache/${key}`)
        .then(() => {
          this.$root.notify(
            this.$t("x_cache_cleared", { key: name.toLowerCase() }),
            "is-success"
          );
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loadingClearKey = -1));
    }
  }
};
</script>
